<template>
	<div class="neirongbox">
		<div class="mycon">
			<div v-html="info.content"></div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'purchaseNotes',
		data() {
			return {
				info: {}
			}
		},
		mounted() {
			this.initFun(3)  //（1:字库介绍，2：版权证书，3：购买需知，4：关于网站，5：关于刘兵克）

		},
		methods: {
			initFun(id) {
				var that = this;
				this.$api.POST(this.$face.zikuSingleInfo, {
					id: id
				}, function(res) {
					that.info = res.data;
					//图片会出现图片不适应100% 的问题
					var richtext = that.info;
					const regex = new RegExp('<img', 'gi');
					var richtext2 = richtext.replace(regex, `<img style="max-width: 100%;display:block;"`);
					that.info = richtext2;
				})
			}
		}
	}
</script>

